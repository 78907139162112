import { createStore } from 'vuex'
import accountsStore from './Modules/Accounts/accountsStore.js'
import companyStore from './Modules/Company/companyStore.js'
import vendorStore from './Modules/Vendors/vendorStore.js'
import messagingStore from './Modules/Messaging/messagingStore.js'
import buyerStore from './Modules/Buyers/buyerStore.js'
import micrositeStore from './Modules/Microsite/micrositeStore.js'
import affiliatesStore from './Modules/Affiliates/affiliatesStore.js'
import landlordStore from './Modules/Landlords/landlordStore.js'

export default createStore ({
    namespaced: true,
    state:{
        marginTop: 0
    },
    getters:{
        getMarginTop(state){
            return state.marginTop;
        }
    },
    mutations:{
        SET_MARGIN_TOP(state, data){
            state.marginTop = data
        }
    },
    actions:{

    },
    modules:{
        accountsStore,
        companyStore,
        vendorStore,
        messagingStore,
        buyerStore,
        micrositeStore,
        affiliatesStore,
        landlordStore
    }
})