import { API } from "aws-amplify";

const STORAGE_KEY = 'user_accounts';

export default {
    namespaced: true,
    state: {
        signedInUser: null,
        userAccounts: null,
        defaultAccount: null,
        selectedAccount: null
    },
    mutations: {
        SET_SIGNED_IN_USER(state, data) {
            state.signedInUser = data;
        },
        SET_USER_ACCOUNTS(state, data) {
            state.userAccounts = data;
            // Save to session storage when accounts are set
            if (data) {
                sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
            } else {
                sessionStorage.removeItem(STORAGE_KEY);
            }
        },
        SET_SELECTED_ACCOUNT(state, data) {
            state.selectedAccount = data;
        },
        INIT_STORE(state) {
            // Load from session storage on store initialization
            const storedAccounts = sessionStorage.getItem(STORAGE_KEY);
            if (storedAccounts) {
                state.userAccounts = JSON.parse(storedAccounts);
                console.log(state.userAccounts)
            }
        }
    },
    getters: {
        accounts(state) {
            if (state.userAccounts) {
                return state.userAccounts;
            }
            return [];
        },
        defaultAccount(state) {
            return state.defaultAccount;
        },
        selectedAccount(state) {
            return state.selectedAccount;
        },
        getAccountByOid: (state) => (oid) => {
            if (!state.userAccounts) return null;
            return state.userAccounts.find(account => account.OID === oid);
        }
    },
    actions: {
        getAccounts({ commit, state, rootGetters }) {
            // Create a promise that resolves when user data is available
            const waitForUser = () => {
                return new Promise((resolve) => {
                    // If user data already exists, resolve immediately
                    if (state.signedInUser?.attributes?.email) {
                        resolve();
                        return;
                    }
        
                    // Otherwise, check every 100ms for user data
                    const interval = setInterval(() => {
                        if (state.signedInUser?.attributes?.email) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100);
        
                    // Add a timeout to prevent infinite waiting
                    setTimeout(() => {
                        clearInterval(interval);
                        resolve();
                    }, 10000); // 10 second timeout
                });
            };
        
            // Wait for user data before proceeding with the API call
            return waitForUser().then(() => {
                // Verify user data exists after waiting
                if (!state.signedInUser?.attributes?.email) {
                    throw new Error('Unable to get user email after waiting');
                }
        
                const params = {
                    body: {
                        clientName: rootGetters['companyStore/shortName'],
                        emailAddress: state.signedInUser.attributes.email
                    }
                };
        
                return API.post("peoplePortalRestAPI", "/accounts", params).then((res) => {
                    if (res.status === "200") {
                        commit("SET_USER_ACCOUNTS", res.data);
                    }
                });
            });
        },
        clearAccountsData({ commit }) {
            commit("SET_USER_ACCOUNTS", null);
            commit("SET_SELECTED_ACCOUNT", null);
        }
    }
};