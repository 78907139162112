import { API } from "aws-amplify";

export default {
    namespaced: true,
    
    // State: Holds a single landlord object that will contain all landlord-related data
    state: {
        landlord: {
            insights: [],
            maintenanceJobs: [],
            maintenanceJobNotes: [],
            rentArrears: {},
            portfolioReport: {},
            profitLossReport: {},
            quickbooksReport: {},
            sasReport: {},
            historicalStatements: [],
            invoices: [],
            tenancies: [],
            tenancyDetails: {},
            tenancyAgent: {},
            tenancyCertificates: [],
            tenancyDocuments: {
                PropertyDocuments: [],
                TenancyDocuments: []
            },
            tenancyMaintenance: [],
            tenancyViewings: {
                Feedback: [],
                UpcomingViewings: [],
                HistoricViewings: []
            }
        }
    },
    
    // Mutations: Methods to directly change the state
    mutations: {
        // SET_LANDLORD_DATA: Updates a specific property of the landlord object
        SET_LANDLORD_DATA(state, { key, data }) {
            state.landlord = { ...state.landlord, [key]: data };
        },

        // Adds an invoice to a landlord
        ADD_INVOICE(state, invoice) {
            if (!state.landlord.invoices) {
                state.landlord.invoices = [];
            }
      
            const invoiceExists = state.landlord.invoices.some(
                existingInvoice => existingInvoice.GlobalReference === invoice.GlobalReference
            );
      
            if (!invoiceExists) {
                state.landlord.invoices.push(invoice);
            }
        },
        
        // Add new mutation for tenancy data
        SET_TENANCY_DATA(state, { tenancyId, key, data }) {
            if (!state.landlord.tenancies) {
                state.landlord.tenancies = [];
            }
            
            const tenancyIndex = state.landlord.tenancies.findIndex(t => t.OID === tenancyId);
            if (tenancyIndex !== -1) {
                state.landlord.tenancies[tenancyIndex] = {
                    ...state.landlord.tenancies[tenancyIndex],
                    [key]: data
                };
            }
        }
    },
    
    // Actions: Methods to perform async operations and commit mutations
    actions: {
        // Get landlord insights
        async getLandlordInsights({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/insights", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "insights", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord insights:", error);
                throw error;
            }
        },

        // Get landlord maintenance jobs
        async getLandlordMaintenanceJobs({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/maintenance-jobs", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "maintenanceJobs", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord maintenance jobs:", error);
                throw error;
            }
        },

        // Get landlord maintenance job notes
        async getLandlordMaintenanceJobNotes({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID,
                        jobID: payload.jobID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/maintenance-job-notes", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "maintenanceJobNotes", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord maintenance job notes:", error);
                throw error;
            }
        },

        // Get landlord rent arrears
        async getLandlordRentArrears({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/rent-arrears", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "rentArrears", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord rent arrears:", error);
                throw error;
            }
        },

        // Get landlord portfolio report
        async getLandlordPortfolioReport({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/reports/portfolio", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "portfolioReport", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord portfolio report:", error);
                throw error;
            }
        },

        // Get landlord profit and loss report
        async getLandlordProfitLossReport({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/reports/profit-loss", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "profitLossReport", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord profit loss report:", error);
                throw error;
            }
        },

        // Get landlord QuickBooks report
        async getLandlordQuickbooksReport({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/reports/quickbooks", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "quickbooksReport", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord quickbooks report:", error);
                throw error;
            }
        },

        // Get landlord SAS report
        async getLandlordSASReport({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/reports/sas", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "sasReport", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord SAS report:", error);
                throw error;
            }
        },

        // Get landlord historical statements
        async getLandlordHistoricalStatements({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/statements/historical", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "historicalStatements", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord historical statements:", error);
                throw error;
            }
        },

        // Download landlord historical statement
        async getLandlordHistoricalStatementDownload(_, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID,
                        invoiceID: payload.invoiceID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/statements/historical/download", params);
                return response;
            } catch (error) {
                console.error("Error downloading landlord historical statement:", error);
                throw error;
            }
        },

        // Get landlord historical statement entries
        async getLandlordHistoricalStatementEntries({commit}, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID,
                        invoiceID: payload.invoiceID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/statements/historical/entries", params);
                if (response.status === "200") {
                    commit("ADD_INVOICE", response.data);
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord historical statement entries:", error);
                throw error;
            }
        },

        // Get landlord pending statement
        async getLandlordPendingStatement({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/statements/pending", params);
                if (response.status === "200") {
                    commit("ADD_INVOICE", response.data);
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord pending statement:", error);
                throw error;
            }
        },

        // Get landlord tenancies
        async getLandlordTenancies({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        LandlordID: payload.LandlordID
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancies", params);
                if (response.status === "200") {
                    commit("SET_LANDLORD_DATA", { key: "tenancies", data: response.data });
                }
                return response;
            } catch (error) {
                console.error("Error getting landlord tenancies:", error);
                throw error;
            }
        },

        // New tenancy-specific actions
        async getTenancyDetails({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", { 
                        tenancyId: payload.tenancyId,
                        key: "details",
                        data: response.data 
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy details:", error);
                throw error;
            }
        },

        async getTenancyAgent({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy/agent", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", {
                        tenancyId: payload.tenancyId,
                        key: "agent",
                        data: response.data
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy agent:", error);
                throw error;
            }
        },

        async getTenancyCertificates({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy/certificates", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", {
                        tenancyId: payload.tenancyId,
                        key: "certificates",
                        data: response.data
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy certificates:", error);
                throw error;
            }
        },

        async getTenancyDocuments({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy/documents", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", {
                        tenancyId: payload.tenancyId,
                        key: "documents",
                        data: response.data
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy documents:", error);
                throw error;
            }
        },

        async getTenancyMaintenance({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy/maintenance", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", {
                        tenancyId: payload.tenancyId,
                        key: "maintenance",
                        data: response.data
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy maintenance:", error);
                throw error;
            }
        },

        async getTenancyViewings({ commit }, payload) {
            try {
                const params = {
                    body: {
                        clientName: payload.clientName,
                        landlordId: payload.landlordId,
                        tenancyId: payload.tenancyId
                    }
                };
                const response = await API.post("peoplePortalRestAPI", "/accounts/landlord/tenancy/viewings", params);
                if (response.status === "200") {
                    commit("SET_TENANCY_DATA", {
                        tenancyId: payload.tenancyId,
                        key: "viewings",
                        data: response.data
                    });
                }
                return response;
            } catch (error) {
                console.error("Error getting tenancy viewings:", error);
                throw error;
            }
        }
    },

    // Getters: Methods to access state in a computed way
    getters: {
        // Get the entire landlord object
        landlord: (state) => state.landlord,
        
        // Basic landlord data getters
        insights: (state) => {
            if(state.landlord.insights)
                return state.landlord.insights;
            else
                return [];
        },
        
        maintenanceJobs: (state) => state.landlord.maintenanceJobs,
        maintenanceJobNotes: (state) => state.landlord.maintenanceJobNotes,
        rentArrears: (state) => state.landlord.rentArrears,
        portfolioReport: (state) => state.landlord.portfolioReport,
        profitLossReport: (state) => state.landlord.profitLossReport,
        quickbooksReport: (state) => state.landlord.quickbooksReport,
        sasReport: (state) => state.landlord.sasReport,
        
        historicalStatements: (state) => {
            if(state.landlord.historicalStatements)
                return state.landlord.historicalStatements;
            else
                return [];
        },
        
        pendingStatement: (state) => state.landlord.pendingStatement,
        
        tenancies: (state) => {
            if(state.landlord.tenancies)
                return state.landlord.tenancies;
            else
                return [];
        },
        
        invoices: (state) => {
            if(state.landlord.invoices)
                return state.landlord.invoices;
            else
                return [];
        },

        // New tenancy-specific getters
        getTenancyDetails: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.details || {};
        },
        
        getTenancyAgent: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.agent || {};
        },
        
        getTenancyCertificates: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.certificates || [];
        },
        
        getTenancyDocuments: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.documents || { PropertyDocuments: [], TenancyDocuments: [] };
        },
        
        getTenancyMaintenance: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.maintenance || [];
        },
        
        getTenancyViewings: (state) => (tenancyId) => {
            const tenancy = state.landlord.tenancies?.find(t => t.OID === tenancyId);
            return tenancy?.viewings || {
                Feedback: [],
                UpcomingViewings: [],
                HistoricViewings: []
            };
        }
    }
};